// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-explore-js": () => import("./../../../src/pages/explore.js" /* webpackChunkName: "component---src-pages-explore-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-returns-and-refunds-policy-js": () => import("./../../../src/pages/returns-and-refunds-policy.js" /* webpackChunkName: "component---src-pages-returns-and-refunds-policy-js" */),
  "component---src-pages-shipping-policy-js": () => import("./../../../src/pages/shipping-policy.js" /* webpackChunkName: "component---src-pages-shipping-policy-js" */),
  "component---src-pages-shop-airport-band-sweatshirt-js": () => import("./../../../src/pages/shop/airport-band-sweatshirt.js" /* webpackChunkName: "component---src-pages-shop-airport-band-sweatshirt-js" */),
  "component---src-pages-shop-alice-dress-js": () => import("./../../../src/pages/shop/alice-dress.js" /* webpackChunkName: "component---src-pages-shop-alice-dress-js" */),
  "component---src-pages-shop-alva-windbreaker-js": () => import("./../../../src/pages/shop/alva-windbreaker.js" /* webpackChunkName: "component---src-pages-shop-alva-windbreaker-js" */),
  "component---src-pages-shop-bag-js": () => import("./../../../src/pages/shop/bag.js" /* webpackChunkName: "component---src-pages-shop-bag-js" */),
  "component---src-pages-shop-baggage-tag-jumpsuit-js": () => import("./../../../src/pages/shop/baggage-tag-jumpsuit.js" /* webpackChunkName: "component---src-pages-shop-baggage-tag-jumpsuit-js" */),
  "component---src-pages-shop-baggage-tag-keyring-js": () => import("./../../../src/pages/shop/baggage-tag-keyring.js" /* webpackChunkName: "component---src-pages-shop-baggage-tag-keyring-js" */),
  "component---src-pages-shop-baggage-tag-sweatshirt-1-js": () => import("./../../../src/pages/shop/baggage-tag-sweatshirt-1.js" /* webpackChunkName: "component---src-pages-shop-baggage-tag-sweatshirt-1-js" */),
  "component---src-pages-shop-baggage-tag-sweatshirt-js": () => import("./../../../src/pages/shop/baggage-tag-sweatshirt.js" /* webpackChunkName: "component---src-pages-shop-baggage-tag-sweatshirt-js" */),
  "component---src-pages-shop-baggage-tag-t-shirt-1-js": () => import("./../../../src/pages/shop/baggage-tag-t-shirt-1.js" /* webpackChunkName: "component---src-pages-shop-baggage-tag-t-shirt-1-js" */),
  "component---src-pages-shop-baggage-tag-t-shirt-js": () => import("./../../../src/pages/shop/baggage-tag-t-shirt.js" /* webpackChunkName: "component---src-pages-shop-baggage-tag-t-shirt-js" */),
  "component---src-pages-shop-bedford-hoodie-js": () => import("./../../../src/pages/shop/bedford-hoodie.js" /* webpackChunkName: "component---src-pages-shop-bedford-hoodie-js" */),
  "component---src-pages-shop-bedford-sweatpant-js": () => import("./../../../src/pages/shop/bedford-sweatpant.js" /* webpackChunkName: "component---src-pages-shop-bedford-sweatpant-js" */),
  "component---src-pages-shop-blanket-js": () => import("./../../../src/pages/shop/blanket.js" /* webpackChunkName: "component---src-pages-shop-blanket-js" */),
  "component---src-pages-shop-bowery-pant-js": () => import("./../../../src/pages/shop/bowery-pant.js" /* webpackChunkName: "component---src-pages-shop-bowery-pant-js" */),
  "component---src-pages-shop-bustier-dress-js": () => import("./../../../src/pages/shop/bustier-dress.js" /* webpackChunkName: "component---src-pages-shop-bustier-dress-js" */),
  "component---src-pages-shop-carmine-leggings-js": () => import("./../../../src/pages/shop/carmine-leggings.js" /* webpackChunkName: "component---src-pages-shop-carmine-leggings-js" */),
  "component---src-pages-shop-carmine-short-js": () => import("./../../../src/pages/shop/carmine-short.js" /* webpackChunkName: "component---src-pages-shop-carmine-short-js" */),
  "component---src-pages-shop-carmine-top-js": () => import("./../../../src/pages/shop/carmine-top.js" /* webpackChunkName: "component---src-pages-shop-carmine-top-js" */),
  "component---src-pages-shop-cart-js": () => import("./../../../src/pages/shop/cart.js" /* webpackChunkName: "component---src-pages-shop-cart-js" */),
  "component---src-pages-shop-clinton-windbreaker-js": () => import("./../../../src/pages/shop/clinton-windbreaker.js" /* webpackChunkName: "component---src-pages-shop-clinton-windbreaker-js" */),
  "component---src-pages-shop-cornelia-pant-js": () => import("./../../../src/pages/shop/cornelia-pant.js" /* webpackChunkName: "component---src-pages-shop-cornelia-pant-js" */),
  "component---src-pages-shop-cornelia-top-js": () => import("./../../../src/pages/shop/cornelia-top.js" /* webpackChunkName: "component---src-pages-shop-cornelia-top-js" */),
  "component---src-pages-shop-index-js": () => import("./../../../src/pages/shop/index.js" /* webpackChunkName: "component---src-pages-shop-index-js" */),
  "component---src-pages-shop-lawton-sweatpant-js": () => import("./../../../src/pages/shop/lawton-sweatpant.js" /* webpackChunkName: "component---src-pages-shop-lawton-sweatpant-js" */),
  "component---src-pages-shop-lott-dress-js": () => import("./../../../src/pages/shop/lott-dress.js" /* webpackChunkName: "component---src-pages-shop-lott-dress-js" */),
  "component---src-pages-shop-oblo-dress-js": () => import("./../../../src/pages/shop/oblo-dress.js" /* webpackChunkName: "component---src-pages-shop-oblo-dress-js" */),
  "component---src-pages-shop-poncho-js": () => import("./../../../src/pages/shop/poncho.js" /* webpackChunkName: "component---src-pages-shop-poncho-js" */),
  "component---src-pages-shop-qr-code-sweatshirt-js": () => import("./../../../src/pages/shop/qr-code-sweatshirt.js" /* webpackChunkName: "component---src-pages-shop-qr-code-sweatshirt-js" */),
  "component---src-pages-shop-rainbow-sweatshirt-js": () => import("./../../../src/pages/shop/rainbow-sweatshirt.js" /* webpackChunkName: "component---src-pages-shop-rainbow-sweatshirt-js" */),
  "component---src-pages-shop-recycled-cashmere-sweatshirt-1-js": () => import("./../../../src/pages/shop/recycled-cashmere-sweatshirt-1.js" /* webpackChunkName: "component---src-pages-shop-recycled-cashmere-sweatshirt-1-js" */),
  "component---src-pages-shop-recycled-cashmere-sweatshirt-js": () => import("./../../../src/pages/shop/recycled-cashmere-sweatshirt.js" /* webpackChunkName: "component---src-pages-shop-recycled-cashmere-sweatshirt-js" */),
  "component---src-pages-shop-skirt-js": () => import("./../../../src/pages/shop/skirt.js" /* webpackChunkName: "component---src-pages-shop-skirt-js" */),
  "component---src-pages-shop-sullivan-dress-js": () => import("./../../../src/pages/shop/sullivan-dress.js" /* webpackChunkName: "component---src-pages-shop-sullivan-dress-js" */),
  "component---src-pages-shop-top-js": () => import("./../../../src/pages/shop/top.js" /* webpackChunkName: "component---src-pages-shop-top-js" */),
  "component---src-pages-shop-tote-denim-js": () => import("./../../../src/pages/shop/tote-denim.js" /* webpackChunkName: "component---src-pages-shop-tote-denim-js" */),
  "component---src-pages-shop-varick-dress-js": () => import("./../../../src/pages/shop/varick-dress.js" /* webpackChunkName: "component---src-pages-shop-varick-dress-js" */),
  "component---src-pages-swiper-lazy-js": () => import("./../../../src/pages/swiper-lazy.js" /* webpackChunkName: "component---src-pages-swiper-lazy-js" */),
  "component---src-pages-vision-js": () => import("./../../../src/pages/vision.js" /* webpackChunkName: "component---src-pages-vision-js" */),
  "component---src-templates-collection-collection-js": () => import("./../../../src/templates/collection/collection.js" /* webpackChunkName: "component---src-templates-collection-collection-js" */)
}

